// base
@import "config/mixin";
@import "config/variables";

html {
  font-size: 62.5%;
}

body {
  color: $common-txt-black;
  font-family: $font-jp;
  font-size: $font-size;
  font-weight: $font-weight-txt;
  letter-spacing: $letter-spacing-txt;
  line-height: $line-height-txt;
  -webkit-text-size-adjust: 100%;
  &.js-headerButtonActive {
    overflow: hidden;
  }
}

img {
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-ttl;
  letter-spacing: $letter-spacing-ttl;
  line-height: $line-height-ttl;
}

address {
  font-style: normal;
}

small {
  font-size: 100%;
}

// common
.screen-reader-text {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

.container {
  width: 100%;
  max-width: calc(1100px + 10%);
  padding: 0 5%;
  margin: 0 auto;
  &.container--large {
    max-width: calc(1280px + 10%);
  }
}

.title {
  display: flex;
  flex-direction: column-reverse;
  color: $color-brown1;
  &__sub {
    font-size: 12vw;
    line-height: 0.9;
    font-family: $font-en2;
    font-weight: 700;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    white-space: nowrap;
    @include mq("md") {
      font-size: 8rem;
    }
  }
  &__main {
    font-size: 3.38vw;
    letter-spacing: 0;
    @include mq("sm") {
      font-size: 1.6rem;
    }
  }
}

.button {
  display: block;
  width: 100%;
  max-width: 280px;
  border-radius: 30px;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 11px 0 10px;
  text-align: center;
  &--white {
    color: $button-white-txt;
    background-color: transparent;
    border: 2px solid $button-white-border;
    transition-property: color, background-color;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
    &:hover,
    &:focus {
      color: $button-white-txt-hover;
      background-color: $button-white-bg-hover;
    }
  }
  &--brown {
    color: $button-brawn-txt;
    background-color: transparent;
    border: 2px solid $button-brawn-border;
    transition-property: color, background-color;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
    &:hover,
    &:focus {
      color: $button-brawn-txt-hover;
      background-color: $button-brawn-bg-hover;
    }
  }
}

// strengths
.strengths {
  @include mq("md") {
    position: relative;
    width: 100%;
    padding-top: 100%;
    width: calc((100% - 24px) / 2);
    padding-top: calc((100% - 24px) / 2);
  }
  @include mq("xl") {
    width: calc((100% - 24px * 3) / 4);
    padding-top: calc((100% - 24px * 3) / 4);
  }
  &:nth-of-type(n + 2) {
    margin-top: 12px;
    @include mq("md") {
      margin-top: 0;
    }
  }
  &:nth-of-type(n + 3) {
    @include mq("md") {
      margin-top: 24px;
    }
    @include mq("xl") {
      margin-top: 0;
    }
  }
  a {
    padding: 4px;
    background-color: $color-white1;
    color: $color-brown3;
    border: 3px solid $color-brown3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition-property: color, background-color;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
    @include mq("md") {
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-width: 4px;
      box-shadow: 0 3px 6px rgba(#000000, 0.16);
      border-radius: 50%;
    }
    &:hover,
    &:focus {
      color: $color-white1;
      background-color: $color-brown3;
    }
  }
  &__title {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-align: center;
    @include mq("md") {
      font-size: 2.3rem;
    }
    &::after {
      content: "";
      display: block;
      width: 200px;
      height: 3px;
      margin: 0 auto 10px;
      background-color: $color-brown3;
      transition-property: background-color;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;
      @include mq("md") {
        width: 185px;
        height: 4px;
        margin: 12px auto 16px;
      }
      .strengths a:hover &,
      .strengths a:focus & {
        background-color: $color-white1;
      }
    }
  }
  &__result {
    font-size: 4.8rem;
    font-weight: 700;
    font-family: $font-en1;
    margin-left: 18px;
    @include mq("md") {
      font-size: 6rem;
      margin-left: 0;
    }
  }
  &__result-unit {
    font-size: 2rem;
    font-family: $font-jp;
    margin-left: 2px;
    @include mq("md") {
      font-size: 2.5rem;
      margin-left: 0;
    }
    &--large {
      font-size: 2.4rem;
      @include mq("md") {
        font-size: 4rem;
        margin-left: 2px;
      }
    }
  }
}

// page-heading
.page-heading1 {
  width: 100%;
  background-image: url("./../img/common/common_page-heading1-bg@2x.png");
  background-size: cover;
  &__inner {
    position: relative;
    height: 160px;
    @include mq("md") {
      height: calc(400 / 1920 * 100vw);
    }
  }
  &__title {
    text-shadow: 0 3px 6px rgba(#000000, 0.5);
    position: absolute;
    top: calc(80 / 1920 * 100vw);
    left: calc(10 / 1920 * 100vw);
    &.title {
      color: $color-white1; // OR
    }
    .title__sub {
      font-size: 4.8rem;
      @include mq("md") {
        font-size: 6.4rem; // OR
      }
      @include mq("xl") {
        font-size: 8rem; // OR
      }
    }
  }
  &__logo {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 64px;
    @include mq("md") {
      width: 15vw;
    }
    @include mq("xl") {
      width: 153px;
    }
  }
}
.page-heading2 {
  width: 100%;
  border-top: 16px solid $color-brown1;
  @include mq("md") {
    border-width: 18px;
  }
  @include mq("xl") {
    border-width: 20px;
  }
  &__inner {
    padding-top: 24px;
    padding-bottom: 8px;
    @include mq("md") {
      padding-top: 40px;
    }
    @include mq("xl") {
      padding-top: 64px;
    }
  }
  &__title {
    .title__sub {
      font-size: 4.8rem;
      @include mq("md") {
        font-size: 6.4rem; // OR
      }
      @include mq("xl") {
        font-size: 8rem; // OR
      }
    }
  }
  &__logo {
    margin-left: auto;
    width: 80px;
    @include mq("md") {
      width: 120px;
    }
    @include mq("xl") {
      margin-right: 40px;
      margin-top: -80px;
      width: 156px;
    }
  }
}

// breadcrumb
.breadcrumb {
  padding-top: 12px;
  padding-bottom: 12px;
  @include mq("xl") {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &__list {
    display: flex;
    align-items: center;
  }
  &__item {
    font-size: 1.4rem;
    font-weight: 700;
    color: $color-brown3;
    @include mq("xl") {
      font-size: 1.8rem;
    }
    a {
      @include hover-action;
    }
    + .breadcrumb__item {
      &::before {
        content: ">";
        color: $color-black1;
        padding-left: 8px;
        padding-right: 8px;
        @include mq("xl") {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }
  }
}

// main
#main {
  margin-top: 80px;
}

// under-title
.under-title {
  font-size: 2.4rem;
  color: $color-gray3;
  letter-spacing: -0.025em;
  border-bottom: 1px solid $color-gray4;
  border-left: 9px solid $color-brown3;
  padding-bottom: 4px;
  padding-left: 12px;
  @include mq("md") {
    font-size: 3.2rem;
    padding-left: 16px;
  }
  @include mq("xl") {
    font-size: 4rem;
    padding-left: 20px;
  }
}

// under-section
.under-section1 {
  &__inner {
    @include mq("xl") {
      padding: 0 4.6875vw 170px;
      margin: 0 auto;
      background-image: linear-gradient(
        to bottom,
        transparent 170px,
        $color-cleam1 170px
      );
    }
  }
  &__box {
    @include mq("xl") {
      display: grid;
      grid-template:
        "image . title" 170px
        "image . text-box" 1fr /
        5fr 1fr 620px;
    }
  }
  &__title {
    @include mq("xl") {
      grid-area: title;
      margin-bottom: 32px;
      margin-left: 10px;
    }
  }
  &__text-box {
    @include mq("xl") {
      grid-area: text-box;
      padding-left: 12px;
      margin-top: 45px;
    }
  }
  &__sub-title {
    margin-top: 24px;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    line-height: 1.5;
    color: $color-brown1;
    @include mq("xl") {
      font-size: 3rem;
      margin-top: 0;
    }
  }
  &__sub-title-top {
    display: inline-block;
    &.under-section1__sub-title-top--sp-inline {
      display: inline;
      @include mq("md") {
        display: inline-block;
      }
    }
  }
  &__sub-title-bottom {
    display: inline-block;
    &.under-section1__sub-title-bottom--sp-inline {
      display: inline;
      @include mq("md") {
        display: inline-block;
      }
    }
  }
  &__text {
    font-size: 1.6rem;
    line-height: 30px;
    margin-top: 16px;
    color: $color-gray3;
    @include mq("xl") {
      margin-top: 30px;
    }
  }
  &__button {
    margin-top: 40px;
    @include mq("xl") {
      margin-top: 56px;
    }
  }
  &__image {
    margin-top: 24px;
    @include mq("xl") {
      max-height: 520px;
      grid-area: image;
      width: 42.1875vw;
      margin-left: calc(100% - 42.1875vw);
      margin-top: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.under-section2 {
  &__content {
    @include mq("xl") {
      display: grid;
      grid-template:
        "title . image"
        "text-box . image" /
        calc(620 / 1100 * 100%) calc(80 / 1100 * 100%) calc(400 / 1100 * 100%);
    }
  }
  &__title {
    @include mq("xl") {
      grid-area: title;
      margin-top: 32px;
    }
  }
  &__text-box {
    @include mq("xl") {
      grid-area: text-box;
      margin-top: 50px;
    }
  }
  &__sub-title {
    margin-top: 24px;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    line-height: 1.5;
    color: $color-brown1;
    @include mq("xl") {
      font-size: 3rem;
      margin-top: 0;
    }
  }
  &__sub-title-top {
    display: inline-block;
    &.under-section2__sub-title-top--sp-inline {
      display: inline;
      @include mq("md") {
        display: inline-block;
      }
    }
  }
  &__sub-title-bottom {
    display: inline-block;
    &.under-section2__sub-title-bottom--sp-inline {
      display: inline;
      @include mq("md") {
        display: inline-block;
      }
    }
  }
  &__text {
    font-size: 1.6rem;
    line-height: 30px;
    margin-top: 16px;
    color: $color-gray3;
    @include mq("xl") {
      margin-top: 30px;
    }
  }
  &__button {
    margin-top: 40px;
    @include mq("xl") {
      margin-top: 56px;
    }
  }
  &__image {
    margin-top: 24px;
    @include mq("xl") {
      max-height: 520px;
      grid-area: image;
      width: 42.1875vw;
      margin-right: calc(100% - 42.1875vw);
      margin-top: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// post
.post {
  &__inner {
    max-width: 1100px;
    margin: 0 auto;
  }
  h2 {
    margin-bottom: 28px;
    @include mq("md") {
      margin-bottom: 44px;
    }
    @include mq("xl") {
      margin-bottom: 52px;
    }
  }
  h3 {
    font-size: 2.2rem;
    color: $color-brown1;
    margin-top: 24px;
    @include mq("md") {
      font-size: 2.6rem;
      margin-top: 40px;
    }
    @include mq("xl") {
      font-size: 3rem;
      margin-top: 48px;
    }
  }
  p {
    font-size: 1.6rem;
    margin-top: 16px;
    word-break: break-word;
    @include mq("md") {
      font-size: 2rem;
      margin-top: 18px;
    }
    + p {
      margin-top: 24px;
      @include mq("md") {
        margin-top: 35px;
      }
    }
    strong {
      font-size: 2rem;
      font-weight: 700;
      @include mq("md") {
        font-size: 2.3rem;
      }
    }
    em {
      color: $color-brown3;
      font-style: normal;
    }
    span {
      font-weight: 500;
    }
  }
}

// media-and-text
.media-and-text {
  margin-top: 40px;
  @include mq("md") {
    margin-top: 64px;
  }
  @include mq("xl") {
    margin-top: 100px;
    display: grid;
    grid-template:
      "image . title" auto
      "image . text" 1fr /
      1fr 80px 1fr;
  }
  + .media-and-text {
    margin-top: 64px;
    @include mq("md") {
      margin-top: 100px;
    }
    @include mq("xl") {
      margin-top: 160px;
    }
  }
  &--image-left {
    @include mq("xl") {
      grid-template:
        "title . image" auto
        "text . image" 1fr /
        1fr 80px 1fr;
    }
  }
  &__image {
    margin-top: 16px;
    @include mq("xl") {
      grid-area: image;
      margin-top: 0;
    }
  }
  &__title {
    font-size: 2rem;
    line-height: 1.5;
    color: $color-brown1;
    letter-spacing: -0.025em;
    @include mq("md") {
      font-size: 2.4rem;
    }
    @include mq("xl") {
      font-size: 3rem;
      grid-area: title;
    }
  }
  &__text {
    font-size: 1.6rem;
    line-height: 1.75;
    margin-top: 16px;
    @include mq("md") {
      font-size: 2rem;
    }
    @include mq("xl") {
      grid-area: text;
    }
  }
}

// card
.card {
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  a {
    @include hover-action;
    display: block;
  }
  &__body {
    padding: 16px;
    @include mq("md") {
      padding: 20px;
    }
    @include mq("xl") {
      padding: 24px;
    }
  }
  &__title {
    font-size: 2rem;
    font-weight: 700;
    @include mq("md") {
      font-size: 2.4rem;
    }
    @include mq("xl") {
      font-size: 3rem;
    }
  }
  &__text {
    font-size: 1.6rem;
    color: $color-gray3;
    line-height: 30px;
    margin-top: 6px;
  }
}

// d-list1
.d-list1 {
  &__inner {
    border-bottom: 1px solid $color-brown3;
    @include mq("xl") {
      display: flex;
    }
  }
  &__term {
    font-size: 1.8rem;
    line-height: 1.5;
    @include mq("md") {
      font-size: 2rem;
    }
    @include mq("xl") {
      font-size: 2.2rem;
      flex-basis: 250px;
      padding: 20px 30px 24px;
      border-right: 1px solid $color-brown3;
    }
  }
  &__description {
    font-size: 1.4rem;
    line-height: 1.5;
    color: $color-gray4;
    padding: 12px 0 16px;
    @include mq("md") {
      font-size: 1.6rem;
      padding: 16px 0 20px;
    }
    @include mq("xl") {
      flex-basis: calc(100% - 250px);
      padding: 20px 0 24px 90px;
    }
  }
}

// header
.header {
  height: 80px;
  background-color: $header-bg-white;
  padding: 16px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  @include mq("xl") {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 4.5%;
    padding-right: 0;
  }
}

.header-title {
  a {
    span {
      transition-property: opacity;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;
    }
    &:hover,
    &:focus {
      span {
        opacity: $hover-opacity;
      }
    }
  }
  &__top {
    font-size: 1.5rem;
    font-weight: 400;
    color: $header-txt-gray;
    display: block;
  }
  &__bottom {
    color: $header-txt-brown;
    margin-top: -7px;
    display: block;
  }
}

.header-nav {
  height: 100%;
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-white1;
    opacity: 0;
    visibility: hidden;
    transition-property: visibility, opacity, background-color;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
    @include mq("xl") {
      flex-direction: row;
      align-items: stretch;
    }
    .js-headerButtonActive & {
      visibility: visible;
      opacity: 1;
    }
    @include mq("xl") {
      position: static;
      height: 100%;
      opacity: 1;
      visibility: visible;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 500;
    a {
      width: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      height: 100%;
      @include mq("xl") {
        width: 138px;
        padding-top: 5px;
      }
    }
    &.header-nav__item--normal {
      a {
        margin-bottom: 16px;
        color: $header-txt-brown;
        @include mq("xl") {
          margin-bottom: 0;
          border-bottom: 8px solid transparent;
          transition-property: color, border-bottom;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;
        }
        &:hover,
        &:focus {
          color: rgba($header-txt-brown, 0.5);
          @include mq("xl") {
            border-color: $header-border-brown;
          }
        }
      }
    }
    &.header-nav__item--faq {
      a {
        color: $header-txt-brown;
        background-color: $header-bg-faq;
        transition-property: background-color;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
        @include mq("xl") {
          padding-bottom: 5px;
          width: 150px;
        }
        &:hover,
        &:focus {
          background-color: rgba($header-bg-faq, $hover-opacity);
        }
      }
    }
    &.header-nav__item--vacancy {
      a {
        color: $header-txt-white;
        background-color: $header-bg-vacancy;
        transition-property: background-color;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
        @include mq("xl") {
          padding-bottom: 5px;
          width: 150px;
        }
        &:hover,
        &:focus {
          background-color: rgba($header-bg-vacancy, $hover-opacity);
        }
      }
    }
    &.header-nav__item--contact {
      a {
        padding-top: 0;
        padding-bottom: 13px;
        display: flex;
        flex-direction: column;
        color: $header-txt-white;
        background-color: $header-bg-contact;
        transition-property: background-color;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
        @include mq("xl") {
          width: 80px;
        }
        &:hover,
        &:focus {
          background-color: rgba($header-bg-contact, $hover-opacity);
        }
        img {
          width: 24px;
        }
        span {
          text-transform: uppercase;
          font-family: $font-en2;
          letter-spacing: -0.075em;
        }
      }
    }
  }
  &__item-top {
    font-size: 1.2rem;
    margin-top: -6px;
  }
  &__item-bottom {
    margin-top: -8px;
  }
}

.header-button {
  width: 48px;
  height: 48px;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20000;
  @include mq("xl") {
    display: none;
  }
  &__line {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 24px;
    height: 2px;
    background-color: $color-brown1;
    transition-property: background-color;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
    .js-headerButtonActive & {
      background-color: transparent;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $color-brown1;
      transition-property: top, transform;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;
      .js-headerButtonActive & {
        top: 0;
      }
    }
    &::before {
      top: -8px;
      .js-headerButtonActive & {
        transform: rotate(45deg);
      }
    }
    &::after {
      top: 8px;
      .js-headerButtonActive & {
        transform: rotate(-45deg);
      }
    }
  }
}

// footer
.footer {
  &__top {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $color-cleam1;
    @include mq("xl") {
      padding-top: 65px;
      padding-bottom: 65px;
    }
  }
  &__top-inner {
    text-align: center;
    @include mq("xl") {
      text-align: left;
      display: flex;
      justify-content: space-between;
    }
  }
  &__bottom {
    padding-top: 16px;
    padding-bottom: 32px;
    background-color: $color-brown1;
    @include mq("xl") {
      padding-top: 20px;
      padding-bottom: 85px;
    }
  }
  &__copyright {
    color: $color-white1;
    text-align: center;
    font-size: 1.4rem;
    @include mq("xl") {
      font-size: 1.6rem;
    }
  }
}

.footer-info {
  padding-top: 6px;
  @include mq("xl") {
    padding-left: 86px;
  }
  &__logo {
    width: 156px;
    margin: 0 auto;
    @include mq("xl") {
      margin: 0;
    }
  }
  &__title {
    margin-top: 16px;
  }
}

.footer-info-title {
  &__top {
    display: block;
    font-size: 1.5rem;
  }
  &__bottom {
    display: block;
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: -6px;
  }
}

.footer-info-address {
  &__tel {
    font-size: 4rem;
    font-family: $font-en1;
    color: $color-brown1;
    margin-top: 6px;
    &--orner {
      margin-top: -4px;
    }
  }
  &__type {
    font-size: 1.5rem;
    font-weight: 500;
    color: $color-brown1;
  }
  &__location {
    font-size: 1.6rem;
    line-height: 22px;
    margin-top: 12px;
  }
}

.footer-nav {
  &__inner {
    margin-top: 40px;
    @include mq("md") {
      width: 680px;
      display: flex;
      justify-content: space-between;
      gap: 50px;
      margin: 40px auto 0;
    }
    @include mq("xl") {
      margin: 0;
    }
  }
  &__item {
    font-size: 1.8rem;
    font-weight: 700;
    color: $color-brown3;
    margin-top: -2px;
    a {
      @include hover-action;
    }
  }
  &__text {
    &.footer-nav__text--disabled {
      color: $color-gray3;
    }
  }
  &__sub-item {
    font-size: 1.6rem;
    font-weight: 400;
    color: $color-gray3;
    margin-top: -6px;
    a {
      transition-property: opacity;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;
      &:hover {
        opacity: 0.7;
      }
      &::before {
        content: "-";
        margin-right: 4px;
        @include mq("xl") {
          margin-left: 16px;
        }
      }
    }
  }
}
