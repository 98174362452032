// hover-action
@mixin hover-action {
  transition-property: opacity;
  transition-timing-function: $transition-timing-function;
  transition-duration: $transition-duration;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}
