@charset "UTF-8";
html {
  font-size: 62.5%;
}

body {
  color: #000000;
  font-family: "YakuHanJP", "Noto Sans JP", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", "Meiryo", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 3.5rem;
  -webkit-text-size-adjust: 100%;
}
body.js-headerButtonActive {
  overflow: hidden;
}

img {
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: -0.075em;
  line-height: 1.5;
}

address {
  font-style: normal;
}

small {
  font-size: 100%;
}

.screen-reader-text {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

.container {
  width: 100%;
  max-width: calc(1100px + 10%);
  padding: 0 5%;
  margin: 0 auto;
}
.container.container--large {
  max-width: calc(1280px + 10%);
}

.title {
  display: flex;
  flex-direction: column-reverse;
  color: #8a5045;
}
.title__sub {
  font-size: 12vw;
  line-height: 0.9;
  font-family: "GFS Didot", serif;
  font-weight: 700;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .title__sub {
    font-size: 8rem;
  }
}
.title__main {
  font-size: 3.38vw;
  letter-spacing: 0;
}
@media screen and (min-width: 640px) {
  .title__main {
    font-size: 1.6rem;
  }
}

.button {
  display: block;
  width: 100%;
  max-width: 280px;
  border-radius: 30px;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 11px 0 10px;
  text-align: center;
}
.button--white {
  color: #ffffff;
  background-color: transparent;
  border: 2px solid #ffffff;
  transition-property: color, background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
.button--white:hover, .button--white:focus {
  color: #773d36;
  background-color: #ffffff;
}
.button--brown {
  color: #773d36;
  background-color: transparent;
  border: 2px solid #773d36;
  transition-property: color, background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
.button--brown:hover, .button--brown:focus {
  color: #ffffff;
  background-color: #8a5045;
}

@media screen and (min-width: 768px) {
  .strengths {
    position: relative;
    width: 100%;
    padding-top: 100%;
    width: calc((100% - 24px) / 2);
    padding-top: calc((100% - 24px) / 2);
  }
}
@media screen and (min-width: 1280px) {
  .strengths {
    width: calc((100% - 24px * 3) / 4);
    padding-top: calc((100% - 24px * 3) / 4);
  }
}
.strengths:nth-of-type(n + 2) {
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  .strengths:nth-of-type(n + 2) {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .strengths:nth-of-type(n + 3) {
    margin-top: 24px;
  }
}
@media screen and (min-width: 1280px) {
  .strengths:nth-of-type(n + 3) {
    margin-top: 0;
  }
}
.strengths a {
  padding: 4px;
  background-color: #ffffff;
  color: #773d36;
  border: 3px solid #773d36;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-property: color, background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
@media screen and (min-width: 768px) {
  .strengths a {
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-width: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
  }
}
.strengths a:hover, .strengths a:focus {
  color: #ffffff;
  background-color: #773d36;
}
.strengths__title {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .strengths__title {
    font-size: 2.3rem;
  }
}
.strengths__title::after {
  content: "";
  display: block;
  width: 200px;
  height: 3px;
  margin: 0 auto 10px;
  background-color: #773d36;
  transition-property: background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
@media screen and (min-width: 768px) {
  .strengths__title::after {
    width: 185px;
    height: 4px;
    margin: 12px auto 16px;
  }
}
.strengths a:hover .strengths__title::after, .strengths a:focus .strengths__title::after {
  background-color: #ffffff;
}
.strengths__result {
  font-size: 4.8rem;
  font-weight: 700;
  font-family: "Anton", sans-serif;
  margin-left: 18px;
}
@media screen and (min-width: 768px) {
  .strengths__result {
    font-size: 6rem;
    margin-left: 0;
  }
}
.strengths__result-unit {
  font-size: 2rem;
  font-family: "YakuHanJP", "Noto Sans JP", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", "Meiryo", sans-serif;
  margin-left: 2px;
}
@media screen and (min-width: 768px) {
  .strengths__result-unit {
    font-size: 2.5rem;
    margin-left: 0;
  }
}
.strengths__result-unit--large {
  font-size: 2.4rem;
}
@media screen and (min-width: 768px) {
  .strengths__result-unit--large {
    font-size: 4rem;
    margin-left: 2px;
  }
}

.page-heading1 {
  width: 100%;
  background-image: url("./../img/common/common_page-heading1-bg@2x.png");
  background-size: cover;
}
.page-heading1__inner {
  position: relative;
  height: 160px;
}
@media screen and (min-width: 768px) {
  .page-heading1__inner {
    height: calc(400 / 1920 * 100vw);
  }
}
.page-heading1__title {
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: calc(80 / 1920 * 100vw);
  left: calc(10 / 1920 * 100vw);
}
.page-heading1__title.title {
  color: #ffffff;
}
.page-heading1__title .title__sub {
  font-size: 4.8rem;
}
@media screen and (min-width: 768px) {
  .page-heading1__title .title__sub {
    font-size: 6.4rem;
  }
}
@media screen and (min-width: 1280px) {
  .page-heading1__title .title__sub {
    font-size: 8rem;
  }
}
.page-heading1__logo {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 64px;
}
@media screen and (min-width: 768px) {
  .page-heading1__logo {
    width: 15vw;
  }
}
@media screen and (min-width: 1280px) {
  .page-heading1__logo {
    width: 153px;
  }
}

.page-heading2 {
  width: 100%;
  border-top: 16px solid #8a5045;
}
@media screen and (min-width: 768px) {
  .page-heading2 {
    border-width: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .page-heading2 {
    border-width: 20px;
  }
}
.page-heading2__inner {
  padding-top: 24px;
  padding-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .page-heading2__inner {
    padding-top: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .page-heading2__inner {
    padding-top: 64px;
  }
}
.page-heading2__title .title__sub {
  font-size: 4.8rem;
}
@media screen and (min-width: 768px) {
  .page-heading2__title .title__sub {
    font-size: 6.4rem;
  }
}
@media screen and (min-width: 1280px) {
  .page-heading2__title .title__sub {
    font-size: 8rem;
  }
}
.page-heading2__logo {
  margin-left: auto;
  width: 80px;
}
@media screen and (min-width: 768px) {
  .page-heading2__logo {
    width: 120px;
  }
}
@media screen and (min-width: 1280px) {
  .page-heading2__logo {
    margin-right: 40px;
    margin-top: -80px;
    width: 156px;
  }
}

.breadcrumb {
  padding-top: 12px;
  padding-bottom: 12px;
}
@media screen and (min-width: 1280px) {
  .breadcrumb {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.breadcrumb__list {
  display: flex;
  align-items: center;
}
.breadcrumb__item {
  font-size: 1.4rem;
  font-weight: 700;
  color: #773d36;
}
@media screen and (min-width: 1280px) {
  .breadcrumb__item {
    font-size: 1.8rem;
  }
}
.breadcrumb__item a {
  transition-property: opacity;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
.breadcrumb__item a:hover, .breadcrumb__item a:focus {
  opacity: 0.7;
}
.breadcrumb__item + .breadcrumb__item::before {
  content: ">";
  color: #000000;
  padding-left: 8px;
  padding-right: 8px;
}
@media screen and (min-width: 1280px) {
  .breadcrumb__item + .breadcrumb__item::before {
    padding-left: 12px;
    padding-right: 12px;
  }
}

#main {
  margin-top: 80px;
}

.under-title {
  font-size: 2.4rem;
  color: #646464;
  letter-spacing: -0.025em;
  border-bottom: 1px solid #707070;
  border-left: 9px solid #773d36;
  padding-bottom: 4px;
  padding-left: 12px;
}
@media screen and (min-width: 768px) {
  .under-title {
    font-size: 3.2rem;
    padding-left: 16px;
  }
}
@media screen and (min-width: 1280px) {
  .under-title {
    font-size: 4rem;
    padding-left: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .under-section1__inner {
    padding: 0 4.6875vw 170px;
    margin: 0 auto;
    background-image: linear-gradient(to bottom, transparent 170px, #fefff0 170px);
  }
}
@media screen and (min-width: 1280px) {
  .under-section1__box {
    display: grid;
    grid-template: "image . title" 170px "image . text-box" 1fr/5fr 1fr 620px;
  }
}
@media screen and (min-width: 1280px) {
  .under-section1__title {
    grid-area: title;
    margin-bottom: 32px;
    margin-left: 10px;
  }
}
@media screen and (min-width: 1280px) {
  .under-section1__text-box {
    grid-area: text-box;
    padding-left: 12px;
    margin-top: 45px;
  }
}
.under-section1__sub-title {
  margin-top: 24px;
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.5;
  color: #8a5045;
}
@media screen and (min-width: 1280px) {
  .under-section1__sub-title {
    font-size: 3rem;
    margin-top: 0;
  }
}
.under-section1__sub-title-top {
  display: inline-block;
}
.under-section1__sub-title-top.under-section1__sub-title-top--sp-inline {
  display: inline;
}
@media screen and (min-width: 768px) {
  .under-section1__sub-title-top.under-section1__sub-title-top--sp-inline {
    display: inline-block;
  }
}
.under-section1__sub-title-bottom {
  display: inline-block;
}
.under-section1__sub-title-bottom.under-section1__sub-title-bottom--sp-inline {
  display: inline;
}
@media screen and (min-width: 768px) {
  .under-section1__sub-title-bottom.under-section1__sub-title-bottom--sp-inline {
    display: inline-block;
  }
}
.under-section1__text {
  font-size: 1.6rem;
  line-height: 30px;
  margin-top: 16px;
  color: #646464;
}
@media screen and (min-width: 1280px) {
  .under-section1__text {
    margin-top: 30px;
  }
}
.under-section1__button {
  margin-top: 40px;
}
@media screen and (min-width: 1280px) {
  .under-section1__button {
    margin-top: 56px;
  }
}
.under-section1__image {
  margin-top: 24px;
}
@media screen and (min-width: 1280px) {
  .under-section1__image {
    max-height: 520px;
    grid-area: image;
    width: 42.1875vw;
    margin-left: calc(100% - 42.1875vw);
    margin-top: 0;
  }
}
.under-section1__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1280px) {
  .under-section2__content {
    display: grid;
    grid-template: "title . image" "text-box . image"/calc(620 / 1100 * 100%) calc(80 / 1100 * 100%) calc(400 / 1100 * 100%);
  }
}
@media screen and (min-width: 1280px) {
  .under-section2__title {
    grid-area: title;
    margin-top: 32px;
  }
}
@media screen and (min-width: 1280px) {
  .under-section2__text-box {
    grid-area: text-box;
    margin-top: 50px;
  }
}
.under-section2__sub-title {
  margin-top: 24px;
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.5;
  color: #8a5045;
}
@media screen and (min-width: 1280px) {
  .under-section2__sub-title {
    font-size: 3rem;
    margin-top: 0;
  }
}
.under-section2__sub-title-top {
  display: inline-block;
}
.under-section2__sub-title-top.under-section2__sub-title-top--sp-inline {
  display: inline;
}
@media screen and (min-width: 768px) {
  .under-section2__sub-title-top.under-section2__sub-title-top--sp-inline {
    display: inline-block;
  }
}
.under-section2__sub-title-bottom {
  display: inline-block;
}
.under-section2__sub-title-bottom.under-section2__sub-title-bottom--sp-inline {
  display: inline;
}
@media screen and (min-width: 768px) {
  .under-section2__sub-title-bottom.under-section2__sub-title-bottom--sp-inline {
    display: inline-block;
  }
}
.under-section2__text {
  font-size: 1.6rem;
  line-height: 30px;
  margin-top: 16px;
  color: #646464;
}
@media screen and (min-width: 1280px) {
  .under-section2__text {
    margin-top: 30px;
  }
}
.under-section2__button {
  margin-top: 40px;
}
@media screen and (min-width: 1280px) {
  .under-section2__button {
    margin-top: 56px;
  }
}
.under-section2__image {
  margin-top: 24px;
}
@media screen and (min-width: 1280px) {
  .under-section2__image {
    max-height: 520px;
    grid-area: image;
    width: 42.1875vw;
    margin-right: calc(100% - 42.1875vw);
    margin-top: 0;
  }
}
.under-section2__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post__inner {
  max-width: 1100px;
  margin: 0 auto;
}
.post h2 {
  margin-bottom: 28px;
}
@media screen and (min-width: 768px) {
  .post h2 {
    margin-bottom: 44px;
  }
}
@media screen and (min-width: 1280px) {
  .post h2 {
    margin-bottom: 52px;
  }
}
.post h3 {
  font-size: 2.2rem;
  color: #8a5045;
  margin-top: 24px;
}
@media screen and (min-width: 768px) {
  .post h3 {
    font-size: 2.6rem;
    margin-top: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .post h3 {
    font-size: 3rem;
    margin-top: 48px;
  }
}
.post p {
  font-size: 1.6rem;
  margin-top: 16px;
  word-break: break-word;
}
@media screen and (min-width: 768px) {
  .post p {
    font-size: 2rem;
    margin-top: 18px;
  }
}
.post p + p {
  margin-top: 24px;
}
@media screen and (min-width: 768px) {
  .post p + p {
    margin-top: 35px;
  }
}
.post p strong {
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .post p strong {
    font-size: 2.3rem;
  }
}
.post p em {
  color: #773d36;
  font-style: normal;
}
.post p span {
  font-weight: 500;
}

.media-and-text {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .media-and-text {
    margin-top: 64px;
  }
}
@media screen and (min-width: 1280px) {
  .media-and-text {
    margin-top: 100px;
    display: grid;
    grid-template: "image . title" auto "image . text" 1fr/1fr 80px 1fr;
  }
}
.media-and-text + .media-and-text {
  margin-top: 64px;
}
@media screen and (min-width: 768px) {
  .media-and-text + .media-and-text {
    margin-top: 100px;
  }
}
@media screen and (min-width: 1280px) {
  .media-and-text + .media-and-text {
    margin-top: 160px;
  }
}
@media screen and (min-width: 1280px) {
  .media-and-text--image-left {
    grid-template: "title . image" auto "text . image" 1fr/1fr 80px 1fr;
  }
}
.media-and-text__image {
  margin-top: 16px;
}
@media screen and (min-width: 1280px) {
  .media-and-text__image {
    grid-area: image;
    margin-top: 0;
  }
}
.media-and-text__title {
  font-size: 2rem;
  line-height: 1.5;
  color: #8a5045;
  letter-spacing: -0.025em;
}
@media screen and (min-width: 768px) {
  .media-and-text__title {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 1280px) {
  .media-and-text__title {
    font-size: 3rem;
    grid-area: title;
  }
}
.media-and-text__text {
  font-size: 1.6rem;
  line-height: 1.75;
  margin-top: 16px;
}
@media screen and (min-width: 768px) {
  .media-and-text__text {
    font-size: 2rem;
  }
}
@media screen and (min-width: 1280px) {
  .media-and-text__text {
    grid-area: text;
  }
}

.card {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.card a {
  transition-property: opacity;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
  display: block;
}
.card a:hover, .card a:focus {
  opacity: 0.7;
}
.card__body {
  padding: 16px;
}
@media screen and (min-width: 768px) {
  .card__body {
    padding: 20px;
  }
}
@media screen and (min-width: 1280px) {
  .card__body {
    padding: 24px;
  }
}
.card__title {
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .card__title {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 1280px) {
  .card__title {
    font-size: 3rem;
  }
}
.card__text {
  font-size: 1.6rem;
  color: #646464;
  line-height: 30px;
  margin-top: 6px;
}

.d-list1__inner {
  border-bottom: 1px solid #773d36;
}
@media screen and (min-width: 1280px) {
  .d-list1__inner {
    display: flex;
  }
}
.d-list1__term {
  font-size: 1.8rem;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .d-list1__term {
    font-size: 2rem;
  }
}
@media screen and (min-width: 1280px) {
  .d-list1__term {
    font-size: 2.2rem;
    flex-basis: 250px;
    padding: 20px 30px 24px;
    border-right: 1px solid #773d36;
  }
}
.d-list1__description {
  font-size: 1.4rem;
  line-height: 1.5;
  color: #707070;
  padding: 12px 0 16px;
}
@media screen and (min-width: 768px) {
  .d-list1__description {
    font-size: 1.6rem;
    padding: 16px 0 20px;
  }
}
@media screen and (min-width: 1280px) {
  .d-list1__description {
    flex-basis: calc(100% - 250px);
    padding: 20px 0 24px 90px;
  }
}

.header {
  height: 80px;
  background-color: #ffffff;
  padding: 16px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
@media screen and (min-width: 1280px) {
  .header {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 4.5%;
    padding-right: 0;
  }
}

.header-title a span {
  transition-property: opacity;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
.header-title a:hover span, .header-title a:focus span {
  opacity: 0.8;
}
.header-title__top {
  font-size: 1.5rem;
  font-weight: 400;
  color: #646464;
  display: block;
}
.header-title__bottom {
  color: #773d36;
  margin-top: -7px;
  display: block;
}

.header-nav {
  height: 100%;
}
.header-nav__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition-property: visibility, opacity, background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
@media screen and (min-width: 1280px) {
  .header-nav__list {
    flex-direction: row;
    align-items: stretch;
  }
}
.js-headerButtonActive .header-nav__list {
  visibility: visible;
  opacity: 1;
}
@media screen and (min-width: 1280px) {
  .header-nav__list {
    position: static;
    height: 100%;
    opacity: 1;
    visibility: visible;
  }
}
.header-nav__item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
}
.header-nav__item a {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}
@media screen and (min-width: 1280px) {
  .header-nav__item a {
    width: 138px;
    padding-top: 5px;
  }
}
.header-nav__item.header-nav__item--normal a {
  margin-bottom: 16px;
  color: #773d36;
}
@media screen and (min-width: 1280px) {
  .header-nav__item.header-nav__item--normal a {
    margin-bottom: 0;
    border-bottom: 8px solid transparent;
    transition-property: color, border-bottom;
    transition-timing-function: ease-out;
    transition-duration: 0.3s;
  }
}
.header-nav__item.header-nav__item--normal a:hover, .header-nav__item.header-nav__item--normal a:focus {
  color: rgba(119, 61, 54, 0.5);
}
@media screen and (min-width: 1280px) {
  .header-nav__item.header-nav__item--normal a:hover, .header-nav__item.header-nav__item--normal a:focus {
    border-color: #773d36;
  }
}
.header-nav__item.header-nav__item--faq a {
  color: #773d36;
  background-color: #b79995;
  transition-property: background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
@media screen and (min-width: 1280px) {
  .header-nav__item.header-nav__item--faq a {
    padding-bottom: 5px;
    width: 150px;
  }
}
.header-nav__item.header-nav__item--faq a:hover, .header-nav__item.header-nav__item--faq a:focus {
  background-color: rgba(183, 153, 149, 0.8);
}
.header-nav__item.header-nav__item--vacancy a {
  color: #ffffff;
  background-color: #a7645c;
  transition-property: background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
@media screen and (min-width: 1280px) {
  .header-nav__item.header-nav__item--vacancy a {
    padding-bottom: 5px;
    width: 150px;
  }
}
.header-nav__item.header-nav__item--vacancy a:hover, .header-nav__item.header-nav__item--vacancy a:focus {
  background-color: rgba(167, 100, 92, 0.8);
}
.header-nav__item.header-nav__item--contact a {
  padding-top: 0;
  padding-bottom: 13px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background-color: #773d36;
  transition-property: background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
@media screen and (min-width: 1280px) {
  .header-nav__item.header-nav__item--contact a {
    width: 80px;
  }
}
.header-nav__item.header-nav__item--contact a:hover, .header-nav__item.header-nav__item--contact a:focus {
  background-color: rgba(119, 61, 54, 0.8);
}
.header-nav__item.header-nav__item--contact a img {
  width: 24px;
}
.header-nav__item.header-nav__item--contact a span {
  text-transform: uppercase;
  font-family: "GFS Didot", serif;
  letter-spacing: -0.075em;
}
.header-nav__item-top {
  font-size: 1.2rem;
  margin-top: -6px;
}
.header-nav__item-bottom {
  margin-top: -8px;
}

.header-button {
  width: 48px;
  height: 48px;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20000;
}
@media screen and (min-width: 1280px) {
  .header-button {
    display: none;
  }
}
.header-button__line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 24px;
  height: 2px;
  background-color: #8a5045;
  transition-property: background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
.js-headerButtonActive .header-button__line {
  background-color: transparent;
}
.header-button__line::before, .header-button__line::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #8a5045;
  transition-property: top, transform;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
.js-headerButtonActive .header-button__line::before, .js-headerButtonActive .header-button__line::after {
  top: 0;
}
.header-button__line::before {
  top: -8px;
}
.js-headerButtonActive .header-button__line::before {
  transform: rotate(45deg);
}
.header-button__line::after {
  top: 8px;
}
.js-headerButtonActive .header-button__line::after {
  transform: rotate(-45deg);
}

.footer__top {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fefff0;
}
@media screen and (min-width: 1280px) {
  .footer__top {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}
.footer__top-inner {
  text-align: center;
}
@media screen and (min-width: 1280px) {
  .footer__top-inner {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
}
.footer__bottom {
  padding-top: 16px;
  padding-bottom: 32px;
  background-color: #8a5045;
}
@media screen and (min-width: 1280px) {
  .footer__bottom {
    padding-top: 20px;
    padding-bottom: 85px;
  }
}
.footer__copyright {
  color: #ffffff;
  text-align: center;
  font-size: 1.4rem;
}
@media screen and (min-width: 1280px) {
  .footer__copyright {
    font-size: 1.6rem;
  }
}

.footer-info {
  padding-top: 6px;
}
@media screen and (min-width: 1280px) {
  .footer-info {
    padding-left: 86px;
  }
}
.footer-info__logo {
  width: 156px;
  margin: 0 auto;
}
@media screen and (min-width: 1280px) {
  .footer-info__logo {
    margin: 0;
  }
}
.footer-info__title {
  margin-top: 16px;
}

.footer-info-title__top {
  display: block;
  font-size: 1.5rem;
}
.footer-info-title__bottom {
  display: block;
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: -6px;
}

.footer-info-address__tel {
  font-size: 4rem;
  font-family: "Anton", sans-serif;
  color: #8a5045;
  margin-top: 6px;
}
.footer-info-address__tel--orner {
  margin-top: -4px;
}
.footer-info-address__type {
  font-size: 1.5rem;
  font-weight: 500;
  color: #8a5045;
}
.footer-info-address__location {
  font-size: 1.6rem;
  line-height: 22px;
  margin-top: 12px;
}

.footer-nav__inner {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .footer-nav__inner {
    width: 680px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin: 40px auto 0;
  }
}
@media screen and (min-width: 1280px) {
  .footer-nav__inner {
    margin: 0;
  }
}
.footer-nav__item {
  font-size: 1.8rem;
  font-weight: 700;
  color: #773d36;
  margin-top: -2px;
}
.footer-nav__item a {
  transition-property: opacity;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
.footer-nav__item a:hover, .footer-nav__item a:focus {
  opacity: 0.7;
}
.footer-nav__text.footer-nav__text--disabled {
  color: #646464;
}
.footer-nav__sub-item {
  font-size: 1.6rem;
  font-weight: 400;
  color: #646464;
  margin-top: -6px;
}
.footer-nav__sub-item a {
  transition-property: opacity;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
}
.footer-nav__sub-item a:hover {
  opacity: 0.7;
}
.footer-nav__sub-item a::before {
  content: "-";
  margin-right: 4px;
}
@media screen and (min-width: 1280px) {
  .footer-nav__sub-item a::before {
    margin-left: 16px;
  }
}